import Cookies from '../js/vendor/js.cookie.min.mjs';

const loader = document.querySelector('.preloader');
const audio = document.querySelector('audio');
const boy = document.querySelector('.boy');
const boyImg = document.getElementById('boyImg');
const boyCount = document.getElementById('boyCount');
const girl = document.querySelector('.girl');
const girlImg = document.getElementById('girlImg');
const girlCount = document.getElementById('girlCount');
const voteLabel = document.querySelector('.vote');
const volume = document.querySelector('.volume-control');
const mute = document.getElementById('mute');
const loud = document.getElementById('loud');
const play = document.getElementById('play');

let isMobile = false;
let isLandscapeMobile = false;
let isLaptop = false;
let isDesktop = true;

let revealed = false;
let playing = false;

mobile();
landscapeMobile();
laptop();
desktop();

window.addEventListener('load', () => {
  loader.classList.add('hide-preloader');

  volume.style.display = `none`;
  mute.style.display = `none`;
  loud.style.display = `none`;
});

window.addEventListener('DOMContentLoaded', () => {
  audio.volume = 0.2;
  audio.pause();

  checkStorage();

  setInterval(() => {
    if (!revealed) {
      getVotes();
    }
  }, 1000);

  setInterval(() => {
    if (!revealed) {
      reveal();
    }
  }, 1000);
});

boyImg.addEventListener('click', () => {
  submitVote('male');
});

girlImg.addEventListener('click', () => {
  submitVote('female');
});

play.addEventListener('click', () => {
  if (!playing) {
    audio.play().then(() => {
      playing = true;
      play.style.display = `none`;
      mute.style.display = `none`;
      loud.style.display = `block`;
    }).catch(() => {
      play.style.display = `block`;
      mute.style.display = `none`;
      loud.style.display = `none`;
    });
  } else {
    audio.muted = false;
    play.style.display = `none`;
    mute.style.display = `none`;
    loud.style.display = `block`;
  }
});

mute.addEventListener('click', () => {
  if (playing) {
    audio.muted = false;
    play.style.display = `none`;
    mute.style.display = `none`;
    loud.style.display = `block`;
  } else {
    mute.style.display = `none`;
    loud.style.display = `none`;
    play.style.display = `block`;
  }
});

loud.addEventListener('click', () => {
  if (playing) {
    audio.muted = true;
    play.style.display = `none`;
    loud.style.display = `none`;
    mute.style.display = `block`;
  } else {
    mute.style.display = `none`;
    loud.style.display = `none`;
    play.style.display = `block`;
  }
});

function submitVote(sex) {
  if (!Cookies.get('sex')) {
    let params = $.param({sex: sex, challenge: 'bm9Tbm9vcGluZ0tlZXRzIQo'})
    $.get("https://app.laidlawreveal.com/vote.php?" + params,
      (data, status) => {
        if (status === 'success') {
          Cookies.set('sex', sex, { expires: 30 });
          checkStorage();
        }
      });
  } else {
    checkStorage()
  }
}

function adjustBackground(boyVote, girlVote) {
  let boyWidth = boy.style.width;

  if (!boyWidth) {
    boyWidth = getComputedStyle(boy).width;
    boyWidth = boyWidth.replace('px', '');
    boyWidth = (+boyWidth * 100 / document.documentElement.clientWidth) + 0.4;
  } else {
    boyWidth = boyWidth.replace('vw', '');
  }

  boyWidth = +boyWidth + boyVote;
  boy.style.width = `${boyWidth}vw`;

  let girlWidth = girl.style.width;

  if (!girlWidth) {
    girlWidth = getComputedStyle(girl).width;
    girlWidth = girlWidth.replace('px', '');
    girlWidth = (+girlWidth * 100 / document.documentElement.clientWidth) + 0.4;
  } else {
    girlWidth = girlWidth.replace('vw', '');
  }

  girlWidth = +girlWidth + girlVote;
  girl.style.width = `${girlWidth}vw`;
}

function getVotes() {
  $.get("https://app.laidlawreveal.com/get-votes.php",
    (data, status) => {
    if (status === 'success') {
      const result = JSON.parse(data);

      boyCount.textContent = result.male;
      girlCount.textContent = result.female;

      boyCount.style.display = `block`;
      girlCount.style.display = `block`;

      if (result.male > result.female) {
        const femalePercent = (result.female / result.male) * 50;
        girl.style.width = `${femalePercent}vw`;
        boy.style.width = `${100 - femalePercent}vw`
      } else {
        const malePercent = (result.male / result.female) * 50;
        boy.style.width = `${malePercent}vw`;
        girl.style.width = `${100 - malePercent}vw`
      }
    }
  });
}

function reveal() {
  $.get("https://app.laidlawreveal.com/reveal.php",
    (data, status) => {
      if (status === 'success') {
        const result = JSON.parse(data);

        if (result.reveal) {
          revealed = true;

          boyImg.classList.remove('image-hover');
          girlImg.classList.remove('image-hover');

          girl.style.width = `50vw`;
          boy.style.width = `50vw`;

          setTimeout(() => {
            adjustBackground(1, -1);
          }, 1500);

          setTimeout(() => {
            adjustBackground(-5, 5);
          }, 2000);

          setTimeout(() => {
            adjustBackground(10, -10);
          }, 3000);

          setTimeout(() => {
            adjustBackground(-15, 15);
          }, 4000);

          setTimeout(() => {
            adjustBackground(40, -40);
          }, 5000);

          setTimeout(() => {
            adjustBackground(13, -13);
          }, 6000);

          setTimeout(() => {
            volume.style.display = `block`;

            girlCount.style.display = `none`;
            boyCount.style.display = `none`;

            audio.muted = false;
            audio.play().then(() => {
              playing = true;
              play.style.display = `none`;
              mute.style.display = `none`;
              loud.style.display = `block`;
            }).catch(() => {
              playing = false;
              play.style.display = `block`;
              mute.style.display = `none`;
              loud.style.display = `none`;
            });

            girl.style.width = `100vw`;
            boy.style.width = `0vw`;
            boyImg.style.width = `0vh`;

            girlImg.style.width = `26vw`;
            girlImg.style.left = `37vw`;

            if (isLaptop) {
              girlImg.style.top = `30vh`;
            } else if (isMobile) {
              girlImg.style.top = `40vh`;
            } else if (isLandscapeMobile) {
              girlImg.style.left = `42vw`;
              girlImg.style.top = `30vh`;
              girlImg.style.width = `15vw`;
            } else {
              girlImg.style.top = `20vh`;
            }
          }, 7000);
        }
      }
    });
}

function checkStorage() {
  if (Cookies.get('sex')) {
    voteLabel.textContent = 'Thanks for Voting!';

    boyImg.classList.remove('image-hover');
    girlImg.classList.remove('image-hover');

    if (Cookies.get('sex') === 'male') {
      boyImg.style.width = `22vw`;
      boyImg.style.left = `14vw`;

      girlImg.style.width = `12vw`;
      girlImg.style.left = `69vw`;

      if (isLaptop) {
        boyImg.style.top = `36vh`;
        girlImg.style.top = `42vh`;
      } else if (isMobile || isLandscapeMobile) {
        boyImg.style.top = `42vh`;
        girlImg.style.top = `45vh`;
      } else {
        boyImg.style.top = `30vh`;
        girlImg.style.top = `36vh`;
      }
    } else if (Cookies.get('sex') === 'female') {
      girlImg.style.width = `20vw`;
      girlImg.style.left = `65vw`;

      boyImg.style.width = `14vw`;
      boyImg.style.left = `11vw`;

      if (isLaptop) {
        girlImg.style.top = `34vh`;
        boyImg.style.top = `40vh`;
      } else if (isMobile || isLandscapeMobile) {
        girlImg.style.top = `42vh`;
        boyImg.style.top = `45vh`;
      } else {
        girlImg.style.top = `28vh`;
        boyImg.style.top = `37vh`;
      }
    }
  }
}

function mobile() {
  const mql = window.matchMedia('screen and (max-width: 500px) and (orientation: portrait)');
  checkMedia(mql);
  mql.addEventListener('change', () => {
    checkMedia(mql);
  });

  function checkMedia(mql) {
    if (mql.matches) {
      isMobile = true;
      isLandscapeMobile = false;
      isLaptop = false;
      isDesktop = false;
    }
  }
}

function laptop() {
  const mql = window.matchMedia('screen and (min-width: 501px) and (max-width: 1024px) and (min-height: 501px)');
  checkMedia(mql);
  mql.addEventListener('change', () => {
    checkMedia(mql);
  });

  function checkMedia(mql) {
    if (mql.matches) {
      isMobile = false;
      isLandscapeMobile = false;
      isLaptop = true;
      isDesktop = false;
    }
  }
}

function desktop() {
  const mql = window.matchMedia('screen and (min-width: 1025px)');
  checkMedia(mql);
  mql.addEventListener('change', () => {
    checkMedia(mql);
  });

  function checkMedia(mql) {
    if (mql.matches) {
      isMobile = false;
      isLandscapeMobile = false;
      isLaptop = false;
      isDesktop = true;
    }
  }
}

function landscapeMobile() {
  const mql = window.matchMedia('screen and (max-width: 850px) and (max-height: 450px');
  checkMedia(mql);
  mql.addEventListener('change', () => {
    checkMedia(mql);
  });

  function checkMedia(mql) {
    if (mql.matches) {
      isMobile = false;
      isLandscapeMobile = true;
      isLaptop = false;
      isDesktop = false;
    }
  }
}
